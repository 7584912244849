import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { AppService } from '@shared/data-access/app/app.service';
import { AuthService } from '@shared/data-access/auth/auth.service';

@Injectable()
export class UserRoleGuard {
    constructor(
        private app: AppService,
        private auth: AuthService,
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!this.auth.isAuthenticated) {
            this.router.navigate(['/auth/login'], { queryParams: { returnUrl: '/' + route.url.join('/') } });
            return;
        }

        const user = this.auth.currentUser;

        if (user.mustResetPassword) {
            this.router.navigate(['/auth/reset-password']);
            return;
        }

        if (user.isAdmin) return true;

        const roles = <Array<string>>(route.data['roles'] || []);

        const hasAccess =
            (!roles.length || roles.some((r) => user.userRole == r))

        if (!hasAccess) {
            this.app.alerts.error(this.app.translate('error.accessDenied'));
        }

        return hasAccess;
    }
}
