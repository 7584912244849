<footer class="site-footer">
    <div class="container">
        <div class="copyright">
            {{ "common.copyright" | translate | formatString : [2024] }}
        </div>
        <div class="logo-container">
            <a [routerLink]="['/']" class="logo">
                <img src="{{ 'footer.logoUrl' | translate }}" alt="logo" />
            </a>
        </div>
    </div>
</footer>
