import { ErrorStateMatcher } from '@angular/material/core';
import { UntypedFormControl, FormGroupDirective, NgForm, NgControl } from '@angular/forms';

export class CustomErrorStateMatcher implements ErrorStateMatcher {
    constructor(private parrent: NgControl | null, private mustTouch: boolean = true, private mustBeDirty: boolean = true) { }

    isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        return control && this.parrent?.control
            && (!this.mustTouch || control.touched || this.parrent.control.touched)
            && (!this.mustBeDirty || control.dirty || this.parrent.control.dirty)
            && this.parrent.control.errors != null;
    }
}
