import { AfterContentInit, Component, ContentChildren, EventEmitter, Input, OnDestroy, Output, QueryList, ViewChild } from '@angular/core';
import {
    MatLegacyColumnDef as MatColumnDef,
    MatLegacyTable as MatTable,
    MatLegacyTableDataSource as MatTableDataSource,
    MatLegacyHeaderRowDef as MatHeaderRowDef,
    MatLegacyRowDef as MatRowDef
} from '@angular/material/legacy-table';
import { TableColumnDirective } from '@shared/directives/column-label.directive';
import { Subscription, debounceTime } from 'rxjs';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { TableColumn } from '@shared/table/data-access/table-column';
import { ITableColumnSettingsParameters } from '@shared/table/data-access/table-column-settings';
import { TableColumnLayout } from '@shared/table/data-access/table-column-layout';
import { TableColumnLayoutStorage } from '@shared/table/data-access/table-column-layout-storage';

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss']
})
export class TableComponent implements AfterContentInit, OnDestroy {
    constructor(private readonly _columnLayoutStorage: TableColumnLayoutStorage) { }

    @Input() customRows: boolean = false
    @Input() multipleDataRows: boolean = false;
    @Input() name: string | null = null;

    @Input() set data(data: any[]) {
        this.dataSource.data = data;
    }

    @Output() columnLayoutRestore: EventEmitter<void> = new EventEmitter<void>();

    @ContentChildren(TableColumnDirective) private columns!: QueryList<TableColumnDirective>;
    @ContentChildren(MatColumnDef) private columnDefs!: QueryList<MatColumnDef>;
    @ContentChildren(MatHeaderRowDef) private headerRowDefs: QueryList<MatHeaderRowDef>;
    @ContentChildren(MatRowDef) private rowDefs: QueryList<MatRowDef<any>>;

    @ViewChild(MatTable, { static: true }) table: MatTable<any>;

    columnLayout: TableColumnLayout | null;
    dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

    private static readonly SETTINGS_SESSION_KEY_PREFIX: string = 'table_settings_';

    private readonly _subscriptions: Subscription[] = [];

    set paginator(paginator: MatPaginator) {
        this.dataSource.paginator = paginator;
    }

    get settingsSessionKey(): string {
        return `${TableComponent.SETTINGS_SESSION_KEY_PREFIX}${this.name}`;
    }

    private get hasCustomRows(): boolean {
        return (this.headerRowDefs && this.headerRowDefs.length > 0)
            || (this.rowDefs && this.rowDefs.length > 0)
    }

    ngAfterContentInit(): void {
        // Without timeout, this causes NG0100: ExpressionChangedAfterItHasBeenCheckedError
        // when injecting custom row defs.
        if (this.hasCustomRows)
            setTimeout(() => this.initializeTableColumnLayout(), 0);
        else
            this.initializeTableColumnLayout();

        if (this.headerRowDefs && this.headerRowDefs.length > 0)
            this.headerRowDefs.forEach(headerRowDef => this.table.addHeaderRowDef(headerRowDef));

        if (this.rowDefs && this.headerRowDefs.length > 0)
            this.rowDefs.forEach(rowDef => this.table.addRowDef(rowDef));
    }

    ngOnDestroy(): void {
        this.columnLayout.destroy();

        this._subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    /**
     * Refresh table data to display relevant items.
     */
    refresh(): void {
        this.columnLayout?.applyToTable(this.table);

        this.dataSource.data = this.dataSource.data;
    }

    /**
     * Restore settings to their default values.
     */
    restoreDefaultSettings(): void {
        this._columnLayoutStorage.remove(this.settingsSessionKey);

        this.initializeTableColumnLayout();
    }

    /**
     * Create default table column layout ({@link TableColumnLayout}).
     *
     * @returns Default table column layout ({@link TableColumnLayout}).
     */
    private createDefaultColumnLayout(): TableColumnLayout {
        const tableColumns: TableColumn[] = [];

        for (let i = 0; i < this.columnDefs.length; i++) {
            const columnDef: MatColumnDef = this.columnDefs.get(i);
            const column: TableColumnDirective = this.columns.get(i);

            const columnSettingsParams: ITableColumnSettingsParameters = <ITableColumnSettingsParameters>{
                display: column.settingDefault,
                hidden: column.settingHidden,
                locked: column.settingLocked
            };

            tableColumns.push(new TableColumn(columnDef, column.label, columnSettingsParams));
        }

        return new TableColumnLayout(tableColumns);
    }
    /**
     * Initialize table column layout.
     *
     * If settings have been configured previously, load them from local storage.
     * Otherwise create and use default settings.
     */
    private initializeTableColumnLayout(): void {
        this.columnLayout = this.createDefaultColumnLayout();

        this.columnLayoutRestore.emit();

        if (this.name)
            this._columnLayoutStorage.restore(this.settingsSessionKey, this.columnLayout);

        this._subscriptions.push(
            this.columnLayout.stateChanges.subscribe(() => this.refresh()));

        this._subscriptions.push(
            this.columnLayout.stateChanges
                .pipe(debounceTime(500))
                .subscribe(() => this._columnLayoutStorage.store(this.settingsSessionKey, this.columnLayout)));

        this.columnLayout.applyToTable(this.table);
    }
}
