import { IPerson } from "@zj/paka-client";
import { AuthType, UserRole, UserType } from "@zj/paka-client/users";

export class User {
    id: string;
    userName: string;
    userRole: UserRole;
    userType: UserType;
    person: IPerson;
    authType: AuthType;
    mustResetPassword: boolean;

    get isAdmin(): boolean {
        return this.userRole == UserRole.Administrator;
    }

    get isAuthenticated(): boolean {
        return !!this.id;
    }

    get displayName(): string {
        if (this.person) {
            return [this.person.firstName, this.person.lastName].filter((t) => !!t).join(' ');
        }

        return this.userName;
    }
}
