import { Subject } from "rxjs";
import { Group } from "./group";
import { IGroupByClause } from "./group-clause.model";

export class GroupManagerOptions {
    constructor(
        public readonly defaultOpen: boolean = true
    ) { }
}

export class GroupManager<T extends object> {
    constructor(
        private readonly options: GroupManagerOptions = new GroupManagerOptions()
    ) { }

    groups: (Group<T> | T)[] = [];
    itemLevelMap: Map<T, number> = new Map<T, number>();
    levelKeyMap: Map<number, Set<any>> = new Map<number, Set<any>>();

    statesChanges: Subject<void> = new Subject<void>();

    private _groupByClauses: IGroupByClause<T>[] = [];
    private _items: T[] = [];
    private _filters: Map<number, any[]> = new Map<number, any[]>();

    get groupByClauses(): IGroupByClause<T>[] {
        return this._groupByClauses;
    }
    set groupByClauses(groupByClauses: IGroupByClause<T>[]) {
        this._groupByClauses = groupByClauses;

        this.group();
    }

    set items(items: T[]) {
        this._items = items;

        this.group();
    }

    filter(level: number, keys: any[]): void {
        this._filters.set(level, keys);

        this.group();
    }

    private group(): void {
        if (this._groupByClauses.length == 0) {
            this.groups = [...this._items];

            return;
        }

        let groups: Group<T>[] = this.groupLevel(this._items as T[]);

        this.groups = groups;

        this.statesChanges.next();
    }

    private groupLevel(items: T[], level: number = 0, levelKeys: any[] = []): Group<T>[] {
        let newGroups: Group<T>[] = [];

        let groupBy: (item: T) => any = this._groupByClauses[level].keyPredicate;

        const keys: Set<any> = new Set<any>();

        items.forEach(item => {
            let key: any = groupBy(item as T);

            keys.add(key);

            if (this.filterOut(level, key))
                return;

            if (newGroups.some(group => group._key == key))
                newGroups.find(group => group._key == key).items.push(item as T);
            else {
                let nextLevelKeys: any[] = [...levelKeys];

                nextLevelKeys.push(key);

                newGroups.push(new Group<T>(key, item, level, this.getOpenState(nextLevelKeys)));
            }

            this.itemLevelMap.set(item as T, level);
        });

        this.levelKeyMap.set(level, keys);

        if (this._groupByClauses.length - 1 > level)
            newGroups.forEach(group => {
                let nextLevelKeys: any[] = [...levelKeys];

                nextLevelKeys.push(group._key);

                group.items = this.groupLevel(group.items as T[], level + 1, nextLevelKeys);
            });

        return newGroups;
    }

    private filterOut(level: number, key: any): boolean {
        const filters: any[] = this._filters.get(level);

        if (!filters || filters.length == 0)
            return false;

        return !filters.some(t => t == key);
    }

    /**
     * Find a group in current groups by group key chain `levelKeys`.
     *
     * @param levelKeys Group key chain.
     *
     * @returns Found group in current groups identified by `levelKeys`.
     * If not found, returns `undefined`.
     */
    private findGroup(levelKeys: any[]): Group<T> | undefined {
        let result: Group<T> | undefined;

        let levelGroups: Group<T>[] = this.groups.filter(group => group instanceof Group)
            .map(group => group as Group<T>);

        for(const levelKey of levelKeys) {
            result = levelGroups.find(group => group._key == levelKey);

            if(!result)
                return result;

            levelGroups = result.items.filter(group => group instanceof Group)
                .map(group => group as Group<T>);
        }

        return result;
    }

    /**
     * Get open state for a group by group key chain `levelKeys`.
     *
     * If group identified by `levelKeys` is not found in current groups,
     * then default value from `this.options` is the result.
     *
     * @param levelKeys Group key chain.
     *
     * @returns Desired `Group<T>.prototype.open` state.
     *
     * @example
     * ```typescript
     * const someGroup: Group<T> = ...;
     * someGroup.open = this.getOpenState([2024, 1]);
     * ```
     */
    private getOpenState(levelKeys: any[]): boolean {
        const group: Group<T> | undefined = this.findGroup(levelKeys);

        return group?.open ?? this.options.defaultOpen;
    }
}
