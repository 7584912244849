import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MaterialModule } from 'src/app/material.module';
import { ArrayJoinPipe } from './pipes/array-join.pipe';
import { AppDatePipe } from './pipes/date.pipe';
import { FormatStringPipe } from './pipes/format-string.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { PagePipe } from './pipes/page.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { TranslatePropertyPipe } from './pipes/translate-property.pipe';
import { FieldErrorDirective } from './directives/field-error.directive';
import { AutoFocusDirective } from './directives/auto-focus.directive';
import { OnlyNumberDirective } from './directives/only-number/only-number.directive';
import { RomanQuarterDirective } from './directives/roman-quarter.directive';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { IntegerOnlyDirective } from './directives/integer-only.directive';
import { PickLocalizedStringPipe } from './pipes/localize-data.pipe';
import { RomanQuarterPipe } from './pipes/roman-quarter.pipe';
import { AlertComponent } from './components/alert/alert.component';
import { ConfirmComponent } from './components/alert/confirm.component';
import { AttachmentsComponent } from './components/inputs/attachments/attachments.component';
import { FileInputComponent } from './components/inputs/file-input/file-input.component';
import { HyperlinkEditComponent } from './components/hyperlink/edit.component';
import { PageNotFoundComponent } from './components/pages/not-found.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { TextMoreLessComponent } from './components/text-more-less/text-more-less.component';
import { TreeComponent } from './components/tree/tree.component';
import { SearchInputComponent } from './components/inputs/search-input/search-input.component';
import { TextEditFieldComponent } from './components/fields/text-edit-field/text-edit-field.component';
import { SelectEditFieldComponent } from './components/fields/select-edit-field/select-edit-field.component';
import { DateEditFieldComponent } from './components/fields/date-edit-field/date-edit-field.component';
import { ServiceDateFieldComponent } from './components/fields/service-date-field/service-date-field.component';
import { ServiceDateFieldInputComponent } from './components/fields/service-date-field/service-date-field-input/service-date-field-input.component';
import { AutocompleteEditFieldComponent } from './components/fields/autocomplete-edit-field/autocomplete-edit-field.component';
import { LocalizationSubjectInputComponent } from './components/inputs/localized-string-input/localization-subject-input.component';
import { PersonEditComponent } from './components/person-edit/person-edit.component';
import { CheckboxFieldComponent } from './components/fields/checkbox-field/checkbox-field.component';
import { ClientSelectFieldComponent } from './components/fields/client-select-field/client-select-field.component';
import { ValueFieldComponent } from './components/fields/value-field/value-field.component';
import { TextFieldComponent } from './components/fields/text-field/text-field.component';
import { DateFieldComponent } from './components/fields/date-field/date-field.component';
import { ServiceDateEditFieldComponent } from './components/fields/service-date-edit-field/service-date-edit-field.component';
import { AutocompleteFieldComponent } from './components/fields/autocomplete-field/autocomplete-field.component';
import { SelectFieldComponent } from './components/fields/select-field/select-field.component';
import { NumberFieldComponent } from './components/fields/number-field/number-field.component';
import { NumberEditFieldComponent } from './components/fields/number-edit-field/number-edit-field.component';
import { FormatDecimalPipe } from './pipes/format-decimal.pipe';
import { FileEditFieldComponent } from './components/fields/file-edit-field/file-edit-field.component';
import { FileFieldComponent } from './components/fields/file-field/file-field.component';
import { PersonSelectFieldComponent } from './components/fields/person-select-field/person-select-field.component';
import { PersonModule as PakaPersonModule } from '@zj/paka-client/persons';
import { ClientModule as PakaClientModule } from '@zj/paka-client/clients';
import { ResponsiblePersonModule as PakaResponsiblePersonModule } from '@zj/paka-client/responsible-persons';
import { AccordionComponent } from './components/accordion/accordion.component';
import { AccordionItemComponent } from './components/accordion/accordion-item/accordion-item.component';
import { SelectableRowsDirective } from './table/directives/selectable-rows.directive';
import { TableWrapperComponent } from './table/components/table-wrapper/table-wrapper.component';
import { SelectionColumnDefComponent } from './table/components/selection-column-def/selection-column-def.component';
import { TableComponent } from './table/components/table/table.component';
import { TableSettingsComponent } from './table/components/table-settings/table-settings.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TableColumnDirective } from './directives/column-label.directive';
import { GroupTableComponent } from './table/components/group-table/group-table.component';
import { GroupTableFilterComponent } from './table/components/group-table/group-table-filter/group-table-filter.component';
import { RichTextEditFieldComponent } from './components/fields/rich-text-edit-field/rich-text-edit-field.component';
import { RichTextFieldComponent } from './components/fields/rich-text-field/rich-text-field.component';
import { QuillModule } from 'ngx-quill';
import { LocalizationSubjectRichTextFieldComponent } from './components/fields/localization-subject-rich-text-field/localization-subject-rich-text-field.component';
import { ResponsiblePersonSelectFieldComponent } from './components/fields/responsible-person-select-field/responsible-person-select-field.component';
import { CheckboxEditFieldComponent } from './components/fields/checkbox-edit-field/checkbox-edit-field.component';
import { MAT_LEGACY_DIALOG_DEFAULT_OPTIONS as MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/legacy-dialog';
import { TextViewMoreModalComponent } from './components/text-view-more/text-view-more-modal/text-view-more-modal.component';
import { TextViewMoreComponent } from './components/text-view-more/text-view-more.component';
import { PasswordFieldComponent } from './components/fields/password-field/password-field.component';
import { AttachmentFieldComponent } from './components/fields/attachment-field/attachment-field.component';
import { MultiValueTextFieldComponent } from './components/fields/multi-value-text-field/multi-value-text-field.component';

export const COMPONENTS = [
    AlertComponent,
    ConfirmComponent,
    AttachmentsComponent,
    FileInputComponent,
    HyperlinkEditComponent,
    PageNotFoundComponent,
    SpinnerComponent,
    TextMoreLessComponent,
    TreeComponent,
    SearchInputComponent,
    TextEditFieldComponent,
    SelectEditFieldComponent,
    DateEditFieldComponent,
    ServiceDateFieldComponent,
    ServiceDateFieldInputComponent,
    AutocompleteEditFieldComponent,
    LocalizationSubjectInputComponent,
    PersonEditComponent,
    GroupTableComponent,
    GroupTableFilterComponent,
    CheckboxEditFieldComponent,
    CheckboxFieldComponent,
    ClientSelectFieldComponent,
    ValueFieldComponent,
    TextFieldComponent,
    DateFieldComponent,
    ServiceDateEditFieldComponent,
    SelectFieldComponent,
    AutocompleteFieldComponent,
    NumberFieldComponent,
    NumberEditFieldComponent,
    FileFieldComponent,
    FileEditFieldComponent,
    PersonSelectFieldComponent,
    AccordionComponent,
    AccordionItemComponent,
    SelectionColumnDefComponent,
    TableWrapperComponent,
    TableComponent,
    TableSettingsComponent,
    RichTextEditFieldComponent,
    RichTextFieldComponent,
    LocalizationSubjectRichTextFieldComponent,
    PasswordFieldComponent,
    ResponsiblePersonSelectFieldComponent,
    TextViewMoreComponent,
    TextViewMoreModalComponent,
    AttachmentFieldComponent,
    MultiValueTextFieldComponent,
];

@NgModule({
    imports: [
        MaterialModule,
        CommonModule,
        PakaClientModule,
        PakaPersonModule,
        PakaResponsiblePersonModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: (http: HttpClient) => {
                    return new TranslateHttpLoader(http);
                },
                deps: [HttpClient]
            }
        }),
        ReactiveFormsModule,
        FormsModule,
        RouterModule,
        NgxMatSelectSearchModule,
        DragDropModule,
        QuillModule.forRoot()
    ],
    exports: [
        ...COMPONENTS,
        SafeHtmlPipe,
        SafeUrlPipe,
        AppDatePipe,
        FormatStringPipe,
        ArrayJoinPipe,
        OrderByPipe,
        PagePipe,
        RomanQuarterPipe,
        TranslatePropertyPipe,
        PickLocalizedStringPipe,
        FieldErrorDirective,
        OnlyNumberDirective,
        AutoFocusDirective,
        RomanQuarterDirective,
        IntegerOnlyDirective,
        FormatDecimalPipe,
        SelectableRowsDirective,
        TableColumnDirective
    ],
    declarations: [
        ...COMPONENTS,
        SafeHtmlPipe,
        SafeUrlPipe,
        AppDatePipe,
        FormatStringPipe,
        ArrayJoinPipe,
        OrderByPipe,
        PagePipe,
        RomanQuarterPipe,
        TranslatePropertyPipe,
        PickLocalizedStringPipe,
        FieldErrorDirective,
        OnlyNumberDirective,
        AutoFocusDirective,
        RomanQuarterDirective,
        IntegerOnlyDirective,
        TableColumnDirective,
        FormatDecimalPipe,
        SelectableRowsDirective
    ],
    providers: [
        { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { disableClose: true } }
    ]
})
export class SharedModule { }
