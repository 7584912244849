import { HttpParams } from "@angular/common/http";

export type SortDir = '' | 'asc' | 'desc';

export interface IPagingParams {
    page?: number;
    take?: number;
    sort?: string;
    sortDir?: SortDir;
}

export class PagingParams {
    constructor({ page, sort, sortDir, take }: IPagingParams) {
        this.page = page || 1;
        this.sort = sort || '';
        this.sortDir = sortDir || '';
        this.take = take;
    }

    setQueryParams(params: HttpParams): void {
        if (this.page) params.append('page', this.page);
        if (this.sort) params.append('sort', this.sort);
        if (this.sortDir) params.append('sortDir', this.sortDir);
        if (this.take) params.append('take', this.take);
    }

    toQueryParams() {
        const result: any = {};

        if (this.page) result.page = this.page;
        if (this.sort) result.sort = this.sort;
        if (this.sortDir) result.sortDir = this.sortDir;
        if (this.take) result.take = this.take;

        return result;
    }

    page: number;
    sort: string;
    sortDir: SortDir;
    take?: number;
}
