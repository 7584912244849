import { Component, Input, Optional, Self } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { PagingParams } from '@core/paging-params';
import { PersonClient, PersonFilter } from '@zj/paka-client/persons';
import { IPersonOption } from '@shared/data-access/person/models/person-option.model';
import { Observable, Subject } from 'rxjs';

@Component({
    selector: 'app-person-select-field',
    templateUrl: './person-select-field.component.html'
})
export class PersonSelectFieldComponent implements ControlValueAccessor {
    constructor(
        private readonly personService: PersonClient,
        @Self() @Optional() private parent: NgControl
    ) {
        this.parent.valueAccessor = this;
    }

    @Input() set initialOption(option: IPersonOption | null) {
        if (option) {
            let existingOption = this.personOptions.find(person => person.id === option.id);

            if (existingOption) {
                existingOption.firstName = option.firstName;
                existingOption.lastName = option.lastName;
                existingOption.email = option.email;
            }
            else {
                this.personOptions.push(option);
            }

            this._optionsChanged.next();
        }
    }
    @Input() inline: boolean = false;
    @Input() isEdit: boolean = false;
    @Input() label: string;
    @Input() hasUserFilter: boolean;

    value: string | null = null;

    get control(): FormControl {
        return this.parent.control as FormControl;
    }

    get selectedOption(): IPersonOption | null {
        return this.value == null
            ? null
            : this.personOptions.find(option => option.id == this.value);
    }

    get optionsChanged(): Observable<void> {
        return this._optionsChanged.asObservable();
    }

    protected personOptions: IPersonOption[] = [];

    private static _lastLoadedOptions: IPersonOption[] = [];

    private readonly _personPaging: PagingParams = new PagingParams({ page: 1, sort: '', sortDir: '', take: 10 });
    private readonly _optionsChanged: Subject<void> = new Subject();

    setValue(value: string): void {
        this.writeValue(value);
        this.onChange(value);
    }

    writeValue(value: string): void {
        this.value = value;
        this.ensureOption();
    }

    registerOnChange(fn: (value: any) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    protected searchPersons(search?: string): void {
        const filter: PersonFilter = new PersonFilter();
        filter.search = search;

        if (this.hasUserFilter)
            filter.hasUser = this.hasUserFilter;

        this.personService.get(this._personPaging, filter).subscribe({
            next: response => {
                this.personOptions = response.items.map(t => <IPersonOption>{
                    id: t.personTechnicalId,
                    firstName: t.firstName,
                    lastName: t.lastName,
                    email: t.email
                });

                PersonSelectFieldComponent._lastLoadedOptions = this.personOptions;

                this._optionsChanged.next();
            }
        });
    }

    private ensureOption(): void {
        if (this.personOptions.find(person => person.id == this.value))
            return;

        let cached: IPersonOption | undefined = PersonSelectFieldComponent._lastLoadedOptions.find(person => person.id == this.value)

        if (cached) {
            this.personOptions.push(cached);

            this._optionsChanged.next();

            return;
        }

        if (this.value)
            this.personService.getById(this.value).subscribe(person => {
                this.personOptions = [<IPersonOption>{
                    id: person.personTechnicalId,
                    firstName: person.firstName,
                    lastName: person.lastName,
                    email: person.email
                }];

                this._optionsChanged.next();
            });
    }

    private onChange = (value: any) => { };
    private onTouch = () => { };
}
