import { NgModule } from '@angular/core';

import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import {
    DateAdapter,
    ErrorStateMatcher,
    MatNativeDateModule,
    MAT_DATE_FORMATS,
    MAT_DATE_LOCALE,
    ShowOnDirtyErrorStateMatcher
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyPaginatorModule as MatPaginatorModule, MAT_LEGACY_PAGINATOR_DEFAULT_OPTIONS as MAT_PAGINATOR_DEFAULT_OPTIONS } from '@angular/material/legacy-paginator';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MomentUtcDateAdapter } from './core/moment-utc-date-adapter';
import { MAT_LEGACY_DIALOG_DEFAULT_OPTIONS as MAT_DIALOG_DEFAULT_OPTIONS, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS as MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/legacy-form-field';
import { MAT_LEGACY_TOOLTIP_DEFAULT_OPTIONS as MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/legacy-tooltip';
import { MAT_LEGACY_TABS_CONFIG as MAT_TABS_CONFIG } from '@angular/material/legacy-tabs';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { TranslateService } from '@ngx-translate/core';
import { CustomMatPaginator } from './core/custom-mat-paginator';
import { AppService } from './shared/data-access/app/app.service';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';

const dateFormatConfig = {
    parse: {
        dateInput: 'DD.MM.YYYY'
    },
    display: {
        dateInput: 'DD.MM.YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY'
    }
};

export function dateLocaleFactory(app: AppService) {
    let locale: string;

    switch (app.currentLanguage) {
        case 'en': locale = 'en-IE'; break;
        case 'lv': locale = 'lv-LV'; break;
        default: locale = navigator.language;
    }

    return locale;
}

@NgModule({
    imports: [
        MatButtonModule,
        MatCheckboxModule,
        MatChipsModule,
        MatSelectModule,
        MatFormFieldModule,
        MatTabsModule,
        MatInputModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatDialogModule,
        MatProgressBarModule,
        MatIconModule,
        MatRadioModule,
        MatTooltipModule,
        MatAutocompleteModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatButtonToggleModule,
        MatMenuModule,
        MatSlideToggleModule
    ],
    exports: [
        MatButtonModule,
        MatChipsModule,
        MatCheckboxModule,
        MatSelectModule,
        MatFormFieldModule,
        MatTabsModule,
        MatInputModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatDialogModule,
        MatProgressBarModule,
        MatIconModule,
        MatRadioModule,
        MatTooltipModule,
        MatAutocompleteModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatButtonToggleModule,
        MatMenuModule
    ],
    providers: [
        { provide: MAT_DATE_LOCALE, useFactory: dateLocaleFactory, deps: [AppService] },
        { provide: DateAdapter, useClass: MomentUtcDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: dateFormatConfig },
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {
                ...new MatDialogConfig(),
                hasBackdrop: true,
                //disableClose: true,
                autoFocus: false,
                minWidth: 300,
                maxWidth: '96vw'
            }
        },
        {
            provide: MAT_TABS_CONFIG,
            useValue: {
                animationDuration: '0ms'
            }
        },
        {
            provide: MatPaginatorIntl,
            deps: [TranslateService],
            useFactory: (translateService: TranslateService) =>
                new CustomMatPaginator(translateService).getPaginatorIntl()
        },
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
        { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: { position: 'above' } },
        {
            provide: MAT_PAGINATOR_DEFAULT_OPTIONS, useValue: {
                pageSize: 10,
                hidePageSize: true,
                showFirstLastButtons: true
            }
        },
        { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher }
    ]
})
export class MaterialModule { }
