<mat-form-field [class.inline]="inline">
    <mat-label *ngIf="!inline">{{ label | translate }}</mat-label>

    <mat-chip-list #chipList>
        <!-- Without style chip causes input container to get bigger -->
        <mat-chip *ngFor="let item of passiveValues" style="margin: 2px 2px 0;" (removed)="remove(item)">
            {{ item }}
            <button matChipRemove>
                <mat-icon>cancel</mat-icon>
            </button>
        </mat-chip>

        <input matInput [ngModel]="activeInput" (ngModelChange)="setValue($event)"
            [disabled]="isDisabled"
            [errorStateMatcher]="errorStateMatcher"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputFor]="chipList"
            [matChipInputAddOnBlur]="true"
            (matChipInputTokenEnd)="add($event)"
            [required]="isRequired"
            (blur)="onBlur()"/>
    </mat-chip-list>

    <button *ngIf="clearEnabled && (activeInput || passiveValues.length > 0)" mat-icon-button matSuffix
        type="button" (click)="clear()">
        <mat-icon>clear</mat-icon>
    </button>

    <mat-hint *ngIf="hint">{{ hint | translate }}</mat-hint>
</mat-form-field>
