import { Component, Input, OnInit, Optional, Self } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { ResponsiblePersonClient } from '@zj/paka-client/responsible-persons';
import { IResponsiblePersonOption } from '@shared/data-access/responsible-person/models/responsible-person-option.model';

@Component({
    selector: 'app-responsible-person-select-field',
    templateUrl: './responsible-person-select-field.component.html'
})
export class ResponsiblePersonSelectFieldComponent implements OnInit, ControlValueAccessor {
    constructor(
        private readonly responsiblePersonClient: ResponsiblePersonClient,
        @Self() @Optional() private parent: NgControl
    ) {
        this.parent.valueAccessor = this;
    }

    @Input() displayClass: string = 'valbox';
    @Input() enableCreation: boolean = false;
    @Input() hasEmptyOption: boolean = false;
    @Input() inline: boolean = false;
    @Input() isEdit: boolean = false;
    @Input() label: string;

    @Input() set initialOption(option: IResponsiblePersonOption | null) {
        if (option) {
            this.displayValue = option;
            this.options.push(option);
        }
    }

    value: string | null = null;
    displayValue: IResponsiblePersonOption | null = null;

    get control(): FormControl {
        return this.parent.control as FormControl;
    }

    protected options: IResponsiblePersonOption[] = [];

    ngOnInit(): void {
        this.loadOptions();
    }

    setValue(value: string): void {
        this.writeValue(value);
        this.onChange(value);
    }

    writeValue(value: string): void {
        this.value = value;
        this.setDisplayValue();
    }

    registerOnChange(fn: (value: string) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    private loadOptions(): void {
        this.responsiblePersonClient.get().subscribe(response => {
            this.options = response.items;
            this.setDisplayValue();
        });
    }

    private setDisplayValue(): void {
        this.displayValue = this.options.find(option => option.id == this.value);
    }

    private onChange = (value: string) => { };
    private onTouch = () => { };
}
