import { Component, Input, Optional, Self } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';

@Component({
    selector: 'app-checkbox-edit-field',
    templateUrl: './checkbox-edit-field.component.html',
    styleUrls: ['./checkbox-edit-field.component.scss']
})
export class CheckboxEditFieldComponent implements ControlValueAccessor {
    constructor(@Self() @Optional() private parent: NgControl) {
        this.parent.valueAccessor = this;
    }

    @Input() inline: boolean = false;
    @Input() isEdit: boolean = false;
    @Input() label: string;

    displayValue: boolean;

    get control(): FormControl {
        return this.parent.control as FormControl;
    }

    setValue(value: boolean): void {
        this.writeValue(value);
        this.onChange(value);
    }

    writeValue(value: boolean): void {
        this.displayValue = value;
    }

    registerOnChange(fn: (value: boolean) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    private onChange = (value: boolean) => { };
    private onTouch = () => { };
}
