import { ClientClient, IClientResponse } from "@zj/paka-client/clients";
import { ErrorHandler } from "../error-handler";
import { Injectable, NgZone } from "@angular/core";
import { AppService } from "@shared/data-access/app/app.service";
import { TemplateParser } from "@shared/data-access/template-parser.service";
import { Observable, map, tap } from "rxjs";

@Injectable({ providedIn: 'root' })
export class APP_CON_01_ErrorHandler implements ErrorHandler {
    constructor(
        private readonly app: AppService,
        private readonly clientClient: ClientClient,
        private readonly ngZone: NgZone
    ) { }

    handle(error: any): Observable<void> {
        const clientId: number = error.error.data.ClientId;

        return this.clientClient.getById(clientId)
            .pipe(
                tap(response => this.handleClientResponse(error, response)),
                map(response => { return; })
            );
    }

    private handleClientResponse(error: any, client: IClientResponse): void {
        this.ngZone.run(() => {
            this.app.hideLoading();
            let text: string = this.app.translate('error.code.APP-CON_01');

            this.app.alerts.error(TemplateParser.parse(text, {
                clientName: client.name,
                registrationNumber: client.registrationNumber,
                status: this.app.translate(`contract.status_.${error.error.data.Status}`)
            }));
        });
    }
}
