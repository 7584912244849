import { Component, Input } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TextViewMoreModalComponent } from './text-view-more-modal/text-view-more-modal.component';

@Component({
    selector: 'app-text-view-more',
    template: `
  {{safeText.length - minHidden <= maxLength ? safeText : safeText.substring(0, maxLength) + '...'}}
  <a href="#" *ngIf="safeText.length - minHidden > maxLength" (click)="viewMore($event)" class="text-view-more">
    <span>{{'btn.viewMore' | translate}} &raquo;</span>
  </a>
    `
})
export class TextViewMoreComponent {
    constructor(private dialog: MatDialog) { }

    @Input() title: string;
    @Input() text: string;
    @Input() maxLength: number = 300;
    /**
     * Minimum amount of text to be hidden. If full text length and max length difference is less than minHidden, then full text is shown.
     */
    @Input() minHidden: number = 50;

    get safeText(): string {
        return this.text || '';
    }

    viewMore(event: any): void {
        event.preventDefault();

        this.dialog.open(TextViewMoreModalComponent, {
            data: {
                title: this.title,
                text: this.text
            },
            width: '1000px'
        })
    }
}
