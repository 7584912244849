<div class="accordion-item">
    <div class="accordion-item-header">
        <div class="accordion-item-action">
            <button mat-icon-button type="button" (click)="toggle()">
                <mat-icon *ngIf="!expanded">expand_more</mat-icon>
                <mat-icon *ngIf="expanded">expand_less</mat-icon>
            </button>
        </div>
        <div class="accordion-item-title" (click)="toggle()">
            <ng-container [ngTemplateOutlet]="titleTemplate ? titleTemplate : defaultTitleTemplate"
                [ngTemplateOutletContext]="{title: title}">
            </ng-container>
        </div>
    </div>

    <div class="accordion-item-body" *ngIf="expanded">
        <ng-content></ng-content>
    </div>
</div>

<ng-template #defaultTitleTemplate let-title="title">
    {{ title | translate }}
</ng-template>
