import { Component, Input, OnInit, Optional, Self } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl, ValidationErrors, Validators } from '@angular/forms';
import { Locale, LocalizationSubject, supportedLocales } from '@zj/paka-client';

@Component({
    selector: 'app-localization-subject-rich-text-field',
    templateUrl: './localization-subject-rich-text-field.component.html'
})
export class LocalizationSubjectRichTextFieldComponent implements ControlValueAccessor, OnInit {
    constructor(@Self() @Optional() private parent: NgControl) {
        this.parent.valueAccessor = this;
    }

    @Input() label: string;

    value: LocalizationSubject | null;
    isDisabled: boolean;
    isRequired: boolean;

    get errors(): ValidationErrors | null {
        return this.parent?.control?.errors || null;
    }

    protected readonly requiredLocales: Locale[] = [Locale.LV];
    protected readonly supportedLocales: Locale[] = supportedLocales;

    ngOnInit(): void {
        this.setRequiredState();
        this.validateValue(this.value);
    }

    setValue(locale: Locale, value: string): void {
        this.value.addOrUpdateValue(locale, value);
        this.onChange(this.value);
        this.validateValue(this.value);
    }

    writeValue(value: LocalizationSubject): void {
        this.value = value;
        this.validateValue(this.value);
    }

    registerOnChange(fn: (value: LocalizationSubject) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    setRequiredState(): void {
        this.isRequired = this.parent?.control?.hasValidator(Validators.required)
            || (this.parent?.control?.validator?.({} as AbstractControl)?.['required'] ?? false);
    }

    private validateValue(value: LocalizationSubject): void {
        if (value == null)
            return;

        const errors: ValidationErrors = {};

        if (this.requiredLocales.length > 0 && this.isRequired && (value.values.length === 0 || value.values.some(t => this.requiredLocales.includes(t.locale) && t.value == null)))
            errors.required = true;

        if (Object.keys(errors).length)
            this.parent.control.setErrors(errors)
    }

    private onChange = (value: LocalizationSubject) => { };
    private onTouch = () => { };
}
