import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from '@shared/components/pages/not-found.component';
import { UserRoleGuard } from '@shared/guards/user-role.guard';

export const routes: Routes = [
    {
        path: 'main',
        canActivate: [UserRoleGuard],
        loadChildren: () => import('./features/main/main.module').then((module) => module.MainPageModule)
    },
    {
        path: 'admin',
        canActivate: [UserRoleGuard],
        loadChildren: () => import('./features/admin/admin.module').then((module) => module.AdminModule)
    },
    {
        path: 'auth',
        loadChildren: () => import('./features/auth/auth.module').then((module) => module.AuthModule)
    },
    {
        path: 'account',
        canActivate: [UserRoleGuard],
        loadChildren: () => import('./features/account/account.module').then((module) => module.AccountModule)
    },
    {
        path: 'cl',
        canActivate: [UserRoleGuard],
        loadChildren: () => import('./features/client/client.module').then((module) => module.ClientModule)
    },
    {
        path: 'minimal-payments',
        canActivate: [UserRoleGuard],
        loadChildren: () => import('./features/minimal-payment/minimal-payment.module').then((module) => module.MinimalPaymentModule)
    },
    {
        path: 'tks',
        canActivate: [UserRoleGuard],
        loadChildren: () => import('./features/tks/tks.module').then((module) => module.TksModule)
    },
    {
        path: 'e-invoices',
        canActivate: [UserRoleGuard],
        loadChildren: () => import('./features/e-invoice/e-invoice.module').then((module) => module.EInvoiceModule)
    },
    {
        path: '',
        redirectTo: '/main',
        pathMatch: 'full'
    },
    {
        path: '**',
        component: PageNotFoundComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        onSameUrlNavigation: 'reload'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
