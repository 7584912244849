import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
    templateUrl: './text-view-more-modal.component.html'
})
export class TextViewMoreModalComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) public readonly data: any
    ) {
        this.title = data.title;
        this.text = data.text;
    }

    protected readonly title: string;
    protected readonly text: string;

    copy() {
        navigator.clipboard.writeText(this.text);
    }
}
