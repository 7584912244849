import { RestrictiveListType } from "@zj/paka-client";

export abstract class RestrictiveList<T> extends Array<T> {
    constructor(comparator?: (a: T, b: T) => boolean) {
        super();

        if (comparator)
            this.comparator = comparator;
    }

    comparator: (a: T, b: T) => boolean
        = (a: T, b: T) => a == b;
    abstract readonly type: RestrictiveListType;

    abstract allow(item: T): void;
    abstract forbid(item: T): void;
    abstract isAllowed(predicate: ((item: T) => boolean)): boolean;
}
