import { Component, OnInit, HostListener, Inject } from '@angular/core';
import {
    Router,
    Event as RouterEvent,
    NavigationStart,
    NavigationCancel,
    NavigationError,
    NavigationEnd
} from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import * as Bowser from "bowser";

import { AppService } from './shared/data-access/app/app.service';
import { IdleTimerFactory } from './core/idle-timer';
import { FontSize } from './core/font-size';
import { ConfirmComponent } from './shared/components/alert/confirm.component';
import { Alert, AlertType, Confirm, ConfirmOptions } from './shared/data-access/app/alert.service';
import { AlertComponent } from './shared/components/alert/alert.component';
import { WindowToken } from './core/window-token';
import { IBreadcrumb } from './core/breadcrumb';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from '@shared/data-access/auth/auth.service';
import { User } from '@shared/data-access/auth/models/user';
import { ParameterClient, ParameterCode } from '@zj/paka-client/parameters';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    constructor(
        private app: AppService,
        private auth: AuthService,
        private params: ParameterClient,
        private router: Router,
        private dialog: MatDialog,
        private snackBar: MatSnackBar,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private idleTimerFactory: IdleTimerFactory,
        @Inject(WindowToken) private window: Window
    ) {
        // uncomment to enable auto-logout functionality
        //this.idleTimer = this.idleTimerFactory.create({
        //  onStart: () => {
        //    if (this.idleLogoutTimeout) {
        //      clearTimeout(this.idleLogoutTimeout);
        //    }

        //    if (this.idleDialog) {
        //      this.idleDialog.close();
        //    }
        //  },
        //  onStop: () => {
        //    if (this.idleLogoutTimeout) {
        //      clearTimeout(this.idleLogoutTimeout);
        //    }

        //    if (this.idleDialog) {
        //      this.idleDialog.close();
        //    }
        //  },
        //  onTimeout: () => {
        //    this.idleLogoutTimeout = setTimeout(() => {
        //      this.router.navigate(['/auth/logout']);
        //    }, this.secondsToForceLogout * 1000);

        //    this.idleDialog = this.showIdle(
        //      () => {
        //        if (this.idleLogoutTimeout) {
        //          clearTimeout(this.idleLogoutTimeout);
        //        }

        //        this.idleTimer.reset();
        //      },
        //      () => {
        //        if (this.idleLogoutTimeout) {
        //          clearTimeout(this.idleLogoutTimeout);
        //        }

        //        this.router.navigate(['/auth/logout']);
        //      });
        //  }
        //});

        this.matIconRegistry.addSvgIcon(
            'vibility',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/visibility.svg')
        );
    }

    user: User;
    fontSize = FontSize.Normal;
    contentUnwrap: boolean;
    breadcrumbs: IBreadcrumb[] = [];

    get isLoading(): boolean {
        return this.app.isLoading();
    }

    private appTitle: string;
    private pageTitle: string;
    private currentRoute: string;
    private isRouteReload: boolean;
    private currentUserId: string;
    private hasErrorDialog: boolean;

    //private readonly idleTimer: IdleTimer;
    //private readonly secondsToForceLogout: number = 30;
    //private idleTimerStarted: boolean;
    //private idleDialog: MatDialogRef<ConfirmComponent>;
    //private idleLogoutTimeout;

    ngOnInit() {
        this.currentUserId = this.app.currentUser.id;

        this.checkBrowser();

        this.app.alerts.getAlert().subscribe((data) => this.showAlert(data));
        this.app.alerts.getNotification().subscribe((data) => this.showNotification(data));
        this.app.alerts.getConfirm().subscribe((data) => this.showConfirm(data));

        this.auth.getUser().subscribe((user) => {
            this.user = user;

            if (user.id != this.currentUserId) {
                // refresh parameters
                this.params.reset();
            }

            if (user.isAuthenticated) {
                //this.params.get().subscribe((data) => {
                //  const idleTimeoutSeconds = +this.params.findValue(parameterCodes.idleTimeout, data);
                //  this.startTimer(idleTimeoutSeconds * 1000);
                //});
            } else {
                //this.idleTimer.stop();
            }

            this.currentUserId = user.id;
        });

        this.app.getFontSize().subscribe((size) => {
            if (size) this.fontSize = size;
        });

        this.params.get().subscribe((data) => {
            this.appTitle = this.params.findValue(ParameterCode.AppTitle, data);
            this.setTitle();
        });

        this.app.getTitle().subscribe((title) => {
            this.pageTitle = title;
            this.setTitle();
        });

        this.app.getBreadcrumbs().subscribe(items => {
            setTimeout(() => {
                const root = items.find(t => t.route === '.');

                if (root) {
                    const parts = this.currentRoute.split('/');
                    root.route = parts.slice(0, -1);
                }

                this.breadcrumbs = items;
            }, 1);
        });
    }

    onRouterOutletActivate(component) {
        this.router.events.subscribe((e) => {
            this.interceptNavigation(e);
        });
    }

    @HostListener('document:click', ['$event'])
    onDocumentClick(event) {
        this.app.documentClick(event);
    }

    private showAlert(alert: Alert) {
        // Show only one error dialog at a time
        const isError = alert.options.type === AlertType.Error;

        if (!isError || !this.hasErrorDialog) {
            const dialogRef = this.dialog.open(AlertComponent, {
                data: alert.options,
                disableClose: true
            });

            if (isError) {
                this.hasErrorDialog = true;
                dialogRef.beforeClosed().subscribe(() => {
                    this.hasErrorDialog = false;
                });
            }

            dialogRef.afterClosed().subscribe(() => {
                alert.result(true);
            });
        }
    }

    private showConfirm(confirm: Confirm) {
        const dialogRef = this.dialog.open(ConfirmComponent, {
            data: confirm.options,
            disableClose: true,
            maxWidth: 800
        });

        dialogRef.afterClosed().subscribe((result: boolean) => {
            confirm.result(result);
        });
    }

    private showNotification(text: string) {
        this.snackBar.open(text, ' ', {
            duration: 5000,
            panelClass: 'app-notification',
            verticalPosition: 'top'
        });
    }

    private showIdle(onContinue: () => void, onLogout: () => void) {
        const dialogRef = this.dialog.open(ConfirmComponent, {
            data: <ConfirmOptions>{
                text: this.app.translate('auth.sessionExpires'),
                ok: this.app.translate('auth.continueSession'),
                cancel: this.app.translate('auth.endSession'),
                title: this.app.translate('auth.userSession')
            },
            disableClose: true,
            maxWidth: 600
        });

        dialogRef.afterClosed().subscribe((result?: boolean) => {
            if (result !== undefined) {
                if (result) {
                    onContinue();
                } else {
                    onLogout();
                }
            }
        });

        return dialogRef;
    }

    private interceptNavigation(event: RouterEvent) {
        // enable route refresh
        if (event instanceof NavigationStart) {
            if (event.url == '/_') {
                return;
            }

            if (!this.isRouteReload && event.url == this.router.url) {
                this.isRouteReload = true;
                const url = event.url;

                this.router.navigateByUrl('/_', { skipLocationChange: true }).then(() => {
                    this.isRouteReload = false;
                    this.router.navigateByUrl(url);
                });

                return;
            }

            this.app.clearLoading();
            this.dialog.closeAll();
        }

        if (event instanceof NavigationCancel) {
            this.app.clearLoading();
        }

        if (event instanceof NavigationError) {
            this.app.clearLoading();
        }

        if (event instanceof NavigationEnd) {
            this.window.scroll(0, 0);

            const route = event.url.split('?')[0];

            if (route !== this.currentRoute) {
                this.pageTitle = undefined;
                this.setTitle();
            }

            this.currentRoute = route;

            this.app.addRouteHistory(route);
        }

        this.checkModule(event);
    }

    private setTitle() {
        document.title = (this.pageTitle ? `${this.pageTitle} | ${this.appTitle}` : this.appTitle) || '...';
    }

    //private startTimer(interval: number) {
    //  if (this.idleTimerStarted || !interval) return;
    //  this.idleTimerStarted = true;
    //  this.idleTimer.start(interval);
    //}

    private checkBrowser() {
        const cacheKey = 'browserChecked';

        if (sessionStorage.getItem(cacheKey) !== 'true') {
            sessionStorage.setItem(cacheKey, 'true');

            const browser = Bowser.getParser(window.navigator.userAgent);

            const isValid = browser.satisfies({
                chrome: ">=78",
                firefox: ">=70",
                edge: ">=79",
                safari: ">=13"
            });

            if (!isValid) {
                this.app.alerts.warning(
                    this.app.translate('common.browserSupportDesc'),
                    this.app.translate('common.browserSupportTitle'));
            }
        }
    }

    private checkModule(event: RouterEvent) {
        //if (event instanceof RoutesRecognized) {
        //  const route = event.state.root.firstChild;
        //  const path = route.routeConfig.path.toLowerCase();

        //  if (path != 'module-name') {
        //    // do something
        //  }
        //}
    }
}
