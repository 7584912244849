<app-select-edit-field [isEdit]="isEdit" [label]="label" [inline]="inline" [hasEmptyOption]="false"
    [searchEnabled]="true" [clearEnabled]="true" [options]="personOptions" [displayTemplate]="personTemplate"
    (searchChange)="searchPersons($event)" [formControl]="control" (ngModelChange)="setValue($event)">
</app-select-edit-field>

<ng-template #personTemplate let-option="option">
    {{ [
    ([option?.firstName, option?.lastName] | arrayJoin: ' '),
    option?.email
    ] | arrayJoin: ', ' }}
</ng-template>
