import { Component, Inject, OnInit } from '@angular/core';
import { AppService } from '@shared/data-access/app/app.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { IPersonCreateRequest, IPersonUpdateRequest, PersonClient } from '@zj/paka-client/persons';
import { IPerson } from '@zj/paka-client';

@Component({
    templateUrl: './person-edit.component.html'
})
export class PersonEditComponent implements OnInit {
    constructor(
        private dialogRef: MatDialogRef<PersonEditComponent>,
        private app: AppService,
        private client: PersonClient,
        private fb: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public readonly data: IPerson
    ) {
        this.id = data.personTechnicalId;
    }

    readonly id: string;

    isEdit: boolean = true;

    form: FormGroup;

    saving: boolean = false;

    public get f(): any {
        return this.form.controls;
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            firstName: [null, [Validators.required]],
            lastName: [null, [Validators.required]],
            email: [null, [Validators.email, Validators.required]],
        });

        this.form.patchValue(this.data);

        if (!!this.id)
            this.load();
    }

    save(): void {
        if (!this.form.valid || !this.form.dirty)
            return;

        const onError = (err) => {
            this.saving = false;
            throw err;
        };

        const onDone = () => {
            this.saving = false;
            this.dialogRef.close(result);
        };

        const onCreated = (id: string) => {
            result.personTechnicalId = id;
            onDone();
        };

        let result: IPerson = {
            ...this.data,
            ...this.form.value,
        };

        this.saving = true;

        if (!!this.id) {
            const model: IPersonUpdateRequest = {
                ...this.form.value
            }

            this.client.update(this.id, model).subscribe(onDone, onError);
        } else {
            const model: IPersonCreateRequest = {
                ...this.form.value
            }

            this.client.create(model).subscribe(onCreated, onError);
        }
    }

    cancel(): void {
        this.confirm(() => this.dialogRef.close(null));
    }

    private load(): void {
        const loading = this.app.showLoading();

        this.client.getById(this.id).subscribe(data => {
            this.app.hideLoading(loading);

            this.form.patchValue(data);
        });
    }

    private confirm(onDone: () => void): void {
        if (this.form.dirty && this.isEdit) {
            this.app.alerts
                .confirm({
                    text: this.app.translate('common.confirmItemCancelEdit')
                })
                .subscribe((result) => {
                    if (!result) return;

                    onDone();
                });

        } else {
            onDone();
        }
    }
}
