<app-date-field *ngIf="isEdit" [inline]="inline" [label]="label" [formControl]="control"
    (ngModelChange)="setValue($event)">
</app-date-field>

<app-value-field *ngIf="!isEdit" [displayClass]="displayClass" [displayTemplate]="dateTemplate" [inline]="inline"
    [label]="label" [value]="displayValue">

    <ng-template #dateTemplate let-value="value">
        {{ value | appDate:displayTime }}
    </ng-template>
</app-value-field>
