import { INavLink } from "./nav-link.model";

export class Menu {
    constructor(name: string, items: (INavLink | Menu)[], text?: string) {
        this.name = name;
        this.text = text;
        this.items = items;
    }

    readonly name: string;
    readonly text?: string;

    readonly items: (INavLink | Menu)[] = [];

    private opened?: boolean;

    private active?: boolean;

    get isOpened(): boolean {
        return this.opened;
    }

    get isActive(): boolean {
        return this.active;
    }

    get isEmpty(): boolean {
        return !this.items.some((t) => t instanceof Menu ? !t.isEmpty : t.visible(t));
    }

    open() {
        this.opened = true;
    }

    close() {
        this.getMenus().forEach((t) => t.close());
        this.opened = false;
    }

    toggle() {
        this.opened = !this.opened;
    }

    isActiveByUrl(url: string): boolean {
        this.active = this.items.filter((t) => t instanceof Menu
            ? t.isActiveByUrl(url)
            : (t.url.split('/').slice(0, 2).join('/') === url)).some(t => t);

        return this.isActive;
    }

    getMenus(): Menu[] {
        return this.items
            .filter((t) => t instanceof Menu)
            .map((t) => t as Menu);
    }

    getNavLinks(): INavLink[] {
        return this.items
            .map((t) => t instanceof Menu ? t.getNavLinks() : [t as INavLink])
            .flat();
    }
}
