<div class="filter-row top-gap">
    <app-select-field *ngFor="let clause of groupManager.groupByClauses;index as i" class="col"
        [displayTemplate]="clauseTemplate" [label]="clause.title" [clearEnabled]="true" [hasEmptyOption]="false"
        [multiple]="true" [ngModel]="values" [options]="getFilterOptions(i)" (ngModelChange)="filter(i, $event)">

        <ng-template #clauseTemplate let-value="value">
            {{ groupManager.groupByClauses[i].filterPredicate ? groupManager.groupByClauses[i].filterPredicate(value) :
            value }}
        </ng-template>
    </app-select-field>
</div>
