import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, BehaviorSubject, of, throwError, retry } from 'rxjs';
import { filter, take, switchMap, catchError } from 'rxjs/operators';

import { AuthService } from '@shared/data-access/auth/auth.service';
import { AppSettings } from '../shared/data-access/app/models/app-settings';

@Injectable({
    providedIn: 'root'
})
export class AuthorizeInterceptor implements HttpInterceptor {
    constructor(private auth: AuthService, private appSettings: AppSettings) { }

    private noTokenUrls: string[] = [];

    private refreshTokenInProgress: boolean = false;
    private refreshTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private refreshTokenUpdated: number;

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const url = req.url.toLowerCase();

        if (this.noTokenRequest(url)) {
            return next.handle(req);
        }

        // cast because of typescript (?)
        return <Observable<HttpEvent<any>>>this.getAccessToken().pipe(
            switchMap((token) => {
                return <any>next.handle(this.addToken(req, token)).pipe(
                    catchError(error => throwError(error))
                );
            }),
            retry({
                count: 1,
                delay: (error: HttpErrorResponse, count: number) => {
                    if (error.status == 401 && count == 1) {
                        // try again with refreshing an auth token
                        if (!this.tokenRefreshedRecently()) {
                            return this.getAccessToken(true);
                        }
                    }

                    throw error;
                }
            })
        );
    }

    private noTokenRequest(requestUrl: string): boolean {
        if (this.noTokenUrls.length == 0 && this.appSettings.apiUrl) {
            this.noTokenUrls = [
                this.auth.loginUrl,
                this.auth.refreshUrl
            ]
        }

        return (
            requestUrl.indexOf('/api/') === -1 ||
            this.noTokenUrls.some((t) => requestUrl.indexOf(t.toLowerCase()) !== -1)
        );
    }

    private tokenRefreshedRecently(): boolean {
        return Date.now() - (this.refreshTokenUpdated || 0) < 30000;
    }

    private getAccessToken(forceRefresh: boolean = false) {
        const token = this.auth.accessToken;

        let shouldRefresh = forceRefresh || (token && this.auth.accessTokenExpired());

        // give the refresher a 30 seconds break
        if (shouldRefresh && this.tokenRefreshedRecently()) {
            shouldRefresh = false;
        }

        if (shouldRefresh) {
            if (this.refreshTokenInProgress) {
                // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
                // which means the new token is ready and we can retry the request again
                return this.refreshTokenSubject.pipe(
                    filter((result) => result !== null),
                    take(1),
                    switchMap((newToken) => of(newToken))
                );
            } else {
                this.refreshTokenInProgress = true;

                // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
                this.refreshTokenSubject.next(null);

                return this.auth.refresh(false).pipe(
                    switchMap((newToken) => {
                        // process current request with the new token
                        this.refreshTokenInProgress = false;
                        this.refreshTokenUpdated = Date.now();
                        this.refreshTokenSubject.next(newToken);
                        return of(newToken);
                    }),
                    catchError(err => {
                        this.refreshTokenInProgress = false;
                        this.refreshTokenUpdated = Date.now();
                        return throwError(err);
                    })
                );
            }
        } else {
            return of(token);
        }
    }

    private addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
        if (!token) return req;

        return req.clone({
            setHeaders: {
                'Authorization': `Bearer ${token}`
            }
        });
    }

    private isSameOriginUrl(req: any) {
        // It's an absolute url with the same origin.
        if (req.url.startsWith(`${window.location.origin}/`)) {
            return true;
        }

        // It's a protocol relative url with the same origin.
        // For example: //www.example.com/api/Products
        if (req.url.startsWith(`//${window.location.host}/`)) {
            return true;
        }

        // It's a relative url like /api/Products
        if (/^\/[^\/].*/.test(req.url)) {
            return true;
        }

        // It's an absolute or protocol relative url that
        // doesn't have the same origin.
        return false;
    }
}
