<h1 mat-dialog-title>
    {{ title | translate }}
</h1>

<div mat-dialog-content>
    {{ text }}
</div>

<div mat-dialog-actions>
    <button mat-flat-button color="primary" (click)="copy()">
        {{ "btn.copy" | translate }}
    </button>

    <button mat-stroked-button mat-dialog-close>
        {{ "btn.close" | translate }}
    </button>
</div>
