import { Directive, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { RowSelectionChange, SelectionColumnDefComponent } from '@shared/table/components/selection-column-def/selection-column-def.component';
import { Subscription } from 'rxjs';
import { TableComponent } from '../components/table/table.component';
import { TableColumn } from '../data-access/table-column';
import { TableColumnSettings } from '../data-access/table-column-settings';
import { RestrictiveList } from '@shared/lists/restrictive-list';
import { WhiteList } from '@shared/lists/white-list';
import { BlackList } from '@shared/lists/black-list';
import { RestrictiveListType } from '@zj/paka-client';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';

@Directive({
    selector: '[appSelectableRows]'
})
export class SelectableRowsDirective implements OnInit, OnDestroy {
    constructor(
        private readonly _table: TableComponent,
        private readonly _viewContainerRef: ViewContainerRef
    ) { }

    paginator: MatPaginator | null = null;
    selected: RestrictiveList<any> = new WhiteList<any>((a, b) => a.id == b.id);

    private _selectionDefComponent: SelectionColumnDefComponent;
    private _selectedAll: boolean = false;

    private readonly _subscriptions: Subscription[] = [];

    get selectedAll(): boolean {
        return this._selectedAll;
    }
    set selectedAll(value: boolean) {
        this._selectedAll = value;

        this._selectionDefComponent.allSelected = this._selectedAll;
    }

    get selectedCount(): number {
        if (this.selected.type == RestrictiveListType.WhiteList)
            return this.selected.length;
        else
            return this._totalItemCount - this.selected.length;
    }

    private get _totalItemCount(): number {
        if (this.paginator)
            return this.paginator.length;

        return this._table.dataSource.data.length
    }

    ngOnInit(): void {
        this._selectionDefComponent = this._viewContainerRef.createComponent(SelectionColumnDefComponent).instance;

        this._selectionDefComponent.selectionAllChange.subscribe(event => this.toggleAll(event));
        this._selectionDefComponent.selectionChange.subscribe(event => this.toggleSelection(event));

        this._table.columnLayoutRestore.subscribe(() => this.addSelectedColumnToTable());

        this._selectionDefComponent.isSelected = this.getIsSelected();
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    reset(): void {
        this.selected = new WhiteList<any>((a, b) => a.id == b.id);

        this.selectedAll = false;
    }

    private addSelectedColumnToTable(): void {
        this._table.columnLayout.addColumn(new TableColumn(this._selectionDefComponent.columnDef, this._selectionDefComponent.columnLabel, new TableColumnSettings({ display: true, locked: true })), 0);
    }

    private checkSelectedAllState(): void {
        const selectedAll: boolean = this.selected.type == RestrictiveListType.BlackList
            ? this.selected.length == 0
            : this.selected.length == this._totalItemCount;

        if (selectedAll != this._selectedAll)
            this.selectedAll = selectedAll;
    }

    private getIsSelected(): (row: any) => boolean {
        const getSelectedAll = () => this._selectedAll;

        return (row) => {
            if (getSelectedAll())
                return true;

            return this.selected.isAllowed(t => t.id == row.id)
        }
    }

    private toggleAll(value: boolean): void {
        this.selected = value
            ? new BlackList<any>((a, b) => a.id == b.id)
            : new WhiteList<any>((a, b) => a.id == b.id);

        this.selectedAll = value;
    }

    private toggleSelection(event: RowSelectionChange): void {
        if (event.value)
            this.selected.allow(event.row);
        else
            this.selected.forbid(event.row);

        this.checkSelectedAllState();
    }
}
