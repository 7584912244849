import { ErrorHandler } from "../error-handler";
import { Injectable, NgZone } from "@angular/core";
import { AppService } from "@shared/data-access/app/app.service";
import { Observable, of } from "rxjs";

@Injectable({ providedIn: 'root' })
export class DefaultErrorCodeHandler implements ErrorHandler {
    constructor(
        private readonly app: AppService,
        private readonly ngZone: NgZone
    ) { }

    handle(error: any): Observable<void> {
        const errorCode: string | null = error.error?.code ?? error.code;

        this.ngZone.run(() => {
            this.app.hideLoading();
            let text: string = this.app.translate('error.code.' + errorCode);

            this.app.alerts.error(text);
        });

        return of();
    }
}
