import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Menu } from '../../data-access/models/menu';
import { INavLink } from '../../data-access/models/nav-link.model';

@Component({
    selector: 'app-sub-header',
    templateUrl: './sub-header.component.html',
    styleUrls: ['./sub-header.component.scss', '../header.component.scss']
})
export class SubHeaderComponent {
    @Input() menu: Menu;

    @Output() toggleMenu: EventEmitter<Menu> = new EventEmitter<Menu>();

    protected getVisible(items: (INavLink | Menu)[]): (INavLink | Menu)[] {
        return items && items.filter((t) => t instanceof Menu ? !t.isEmpty : !t.visible || t.visible(t));
    }

    protected isMenu(n: INavLink | Menu): boolean {
        return n instanceof Menu;
    }

    protected getMenu(n: INavLink | Menu): Menu {
        return n instanceof Menu ? n : null;
    }
}
