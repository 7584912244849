import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import localeLv from '@angular/common/locales/lv';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { MaterialModule } from './material.module';

// core
import { AppErrorHandler } from './core/app-error-handler';
import { AuthorizeInterceptor } from './core/authorize-interceptor';
import { LanguageInterceptor } from './core/language-interceptor';
import { UserRoleGuard } from './shared/guards/user-role.guard';
import { WindowToken, windowProvider } from './core/window-token';

// routes
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { MainPageModule } from './features/main/main.module';
import { AppInitService } from './shared/data-access/app/app-init.service';
import { AppSettings } from './shared/data-access/app/models/app-settings';
import { PAKA_API_URL } from '@zj/paka-client';
import { ParameterModule as PakaParameterModule } from '@zj/paka-client/parameters';

registerLocaleData(localeLv);

function appInitializerFactory(service: AppInitService) {
    return service.initialize();
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        HttpClientModule,
        AppRoutingModule,
        PakaParameterModule,
        MainPageModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (http: HttpClient) => {
                    return new TranslateHttpLoader(http);
                },
                deps: [HttpClient]
            }
        }),
        MaterialModule,
        BrowserAnimationsModule,
        SharedModule
    ],
    providers: [
        AppSettings,
        { provide: LOCALE_ID, useValue: 'lv-LV' },
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [AppInitService],
            multi: true
        },
        {
            provide: ErrorHandler,
            useClass: AppErrorHandler
        },
        { provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
        { provide: WindowToken, useFactory: windowProvider },
        { provide: PAKA_API_URL, useFactory: (appSettings: AppSettings) => appSettings.apiUrl, deps: [AppSettings] },
        UserRoleGuard
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
