import { Pipe, PipeTransform, Inject, LOCALE_ID } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
    name: 'appDate' // "date" pipe is already reserved
})
export class AppDatePipe extends DatePipe implements PipeTransform {
    constructor(@Inject(LOCALE_ID) locale: string) {
        super(locale);
        this.localeId = locale;
    }

    private localeId: string;

    transform(value: string | null, args?: any): any {
        let format: string;
        switch ((this.localeId || '').toLowerCase().split('-')[0]) {
            case 'lv':
            case 'en':
                format = 'dd.MM.yyyy';
                break;
            // TODO other formats...
            default:
                format = 'yyyy-MM-dd';
        }

        if (args === true) {
            format += ' HH:mm:ss';
            args = undefined;
        }

        if (value != null) {
            // Remove timezone offset
            value = value.split(/[+-]\d{2}:\d{2}$/)[0];

            // Mark as UTC datetime
            if (!value.endsWith('Z'))
                value += 'Z';
        }

        return super.transform(value, args || format);
    }
}
