<app-select-field *ngIf="isEdit" [label]="label" [inline]="inline" [hasEmptyOption]="hasEmptyOption"
    [displayTemplate]="displayTemplate" [options]="options" [formControl]="control" (ngModelChange)="setValue($event)">
</app-select-field>

<app-value-field *ngIf="!isEdit" [displayClass]="displayClass" [displayTemplate]="displayTemplate" [inline]="inline"
    [label]="label" [value]="displayValue">
</app-value-field>

<ng-template #displayTemplate let-value="value">
    <ng-container *ngIf="value">
        [{{ value.code}}] <i>{{ value.firstName }} {{ value.lastName }}</i>
    </ng-container>
</ng-template>
