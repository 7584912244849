import { Component, EventEmitter, Input, OnInit, Optional, Output, Self } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl, ValidationErrors, Validators } from '@angular/forms';
import { Util } from '@zj/paka-client';

@Component({
    selector: 'app-file-field',
    templateUrl: './file-field.component.html'
})
export class FileFieldComponent implements ControlValueAccessor, OnInit {
    constructor(@Self() @Optional() private parent: NgControl) {
        this.parent.valueAccessor = this;
    }

    @Input() acceptExtensions: string;
    @Input() clearEnabled: boolean = true;
    @Input() downloadEnabled: boolean = true;
    @Input() hint: string;
    @Input() inline: boolean = false;
    @Input() label: string;
    @Input() maxSize: number;

    @Output() download: EventEmitter<any> = new EventEmitter();

    value: File;
    isDisabled: boolean;
    isRequired: boolean;

    get errors(): ValidationErrors | null {
        return this.parent?.control?.errors || null;
    }

    readonly prettySize = Util.prettyFileSize;

    ngOnInit(): void {
        this.setRequiredState();
    }

    setValue(value: File): void {
        this.value = value;
        this.onChange(this.value);
        this.validateValue(this.value);
    }

    writeValue(value: File): void {
        this.value = value;
    }

    registerOnChange(fn: (value: File) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    setRequiredState(): void {
        this.isRequired = this.parent?.control?.hasValidator(Validators.required)
            || (this.parent?.control?.validator?.({} as AbstractControl)?.['required'] ?? false);
    }

    onBlur(): void {
        this.onTouch();
    }

    private validateValue(value: File): void {
        if (value == null)
            return;

        const errors: ValidationErrors = {};

        if (this.maxSize && value.size > this.maxSize)
            errors.fileMaxSize = { value: this.maxSize };

        if (this.acceptExtensions) {
            const extension = value.name.substring(value.name.lastIndexOf('.') + 1).toLowerCase();

            if (!this.acceptExtensions.includes(extension) && !this.acceptExtensions.includes('.' + extension))
                errors.fileExtension = { value: this.acceptExtensions };
        }

        if (Object.keys(errors).length)
            this.parent.control.setErrors(errors)
    }

    private onChange = (value: File) => { };
    private onTouch = () => { };
}
