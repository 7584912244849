import { RestrictiveListType, Util } from "@zj/paka-client";
import { RestrictiveList } from "./restrictive-list";

export class WhiteList<T> extends RestrictiveList<T> {
    readonly type: RestrictiveListType = RestrictiveListType.WhiteList;

    allow(item: T): void {
        this.push(item);
    }

    forbid(item: T): void {
        const itemToRemove: T | undefined = this.find(t => this.comparator(t, item));

        if (itemToRemove)
            Util.removeItemFromArray(this, itemToRemove);
    }

    isAllowed(predicate: ((item: T) => boolean)): boolean {
        return this.find(predicate) ? true : false;
    }
}
